// app/components/SuccessDialog.tsx
import React, { useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import { useNavigate } from "@remix-run/react";
import { Button } from "../ui/button";

interface SuccessDialogProps {
  title?: string;
  message?: string;
  buttonType?: "primary" | "secondary";
  buttonText?: string;
  onClose?: () => void;
  onRedirect?: () => void;
  countdownStart?: number; // Optional prop to set countdown start (default 10)
}

const SuccessDialog: React.FC<SuccessDialogProps> = ({
  title,
  message,
  buttonText,
  buttonType = "primary",
  onClose,
  onRedirect,
  countdownStart = 5,
}) => {
  const navigate = useNavigate();

  const [countdown, setCountdown] = useState<number>(countdownStart);
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  useEffect(() => {
    let timer = null;
    if (onRedirect) {
      if (countdown <= 0) {
        console.log(countdown);
        onRedirect();
        return;
      }

      timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
    }

    if (timer) {
      return () => clearInterval(timer);
    }
  }, [countdown, onRedirect]);

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else if (onRedirect) {
      onRedirect();
    } else {
      navigate(-1);
    }
  };

  return (
    <Transition
      show={isVisible}
      enter="transition-opacity duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="fixed inset-0 flex items-center justify-center bg-white z-50">
        <div className="bg-white rounded-lg max-w-md w-full transform transition-all">
          <div className="flex flex-col items-center">
            <div className="">
              <img src="/error_1.svg" alt="Error" className="mb-8 w-60 mr-8" />
            </div>
            {title && (
              <h2 className="text-xl font-medium text-gray-700 mb-2">
                {title}
              </h2>
            )}
            {message && (
              <p className="text-md mb-4 text-center font-light text-gray-400 max-w-72">
                {message}
              </p>
            )}
            {buttonText && (
              <div className="w-full px-8 mt-4">
                {buttonType === "primary" ? (
                  <Button onClick={handleClose} className={`w-full`}>
                    {buttonText}
                  </Button>
                ) : (
                  <Button onClick={handleClose} className={`w-full`}>
                    {buttonText}
                  </Button>
                )}
              </div>
            )}
            {onRedirect && (
              <p className="text-sm text-gray-500 mt-6">
                Redirecting in {countdown} second{countdown !== 1 ? "s" : ""}...
              </p>
            )}
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default SuccessDialog;
