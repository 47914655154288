import { isRouteErrorResponse, useRouteError } from "@remix-run/react";
import ErrorModel from "./Error";

interface ErrorBoundaryProps {
  title?: string;
  message?: string;
  onClose?: () => void;
  onRetry?: () => void;
}

export default function ErrorBoundary({
  title,
  message,
  onClose,
  onRetry,
}: ErrorBoundaryProps) {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    return (
      <div>
        <ErrorModel
          title={title || "Oops"}
          message={message || error.statusText || "Something wend wrong!"}
          onClose={onRetry}
          buttonType="primary"
          buttonText="Retry"
          // onRedirect={onRetry}
        />
      </div>
    );
  } else if (error instanceof Error) {
    return (
      <ErrorModel
        title={title || "Sorry, something Went Wrong!"}
        message={
          message || "We’re working on getting this fixed as soon as we can."
        }
        onClose={onClose}
        buttonType="secondary"
        buttonText="Close"
        // onRetry={onRetry}
      />
    );
  } else {
    return <ErrorModel title="Sorry" message={"Unknown Error"} />;
  }
}
